.fa-login {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__wrapper {
    position: relative;
  }

  &__card {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 436px;
    height: 460px;
    background-color: rgba(0,0,0,0.5);
    border-bottom: 4px solid #E56727;
    padding-top: 12px;

    &__title {
      margin: 0;
      color: var(--white);
      text-align: center;
      font-size: 24px;
      font-weight: 300;
    }

    &__divider {
      width: 95px;
      height: 5px;
      border: none;
      background-color: #E56727;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    &__select-region {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 400px;
      height: 210px;
      margin: 0 auto;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &__card-shadow {
    position: absolute;
    bottom: -25px;
    width: 438px;
    height: 43px;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.7);
    -webkit-filter: blur(35px);
    -moz-filter: blur(35px);
    -o-filter: blur(35px);
    -ms-filter: blur(35px);
    filter: blur(35px);
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 50px;
  }
}

.login-error {
  line-height: normal;
  border: 1px solid var(--error-main);
  margin-top: 16px;
  margin-bottom: 24px;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
}

.icon-select {
  .MuiInputBase-root {
    border-radius: 3px;
    background-color: var(--white);

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--neutral-tertiary);
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: var(--neutral-tertiary);
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: var(--neutral-tertiary);
    }

    .MuiSelect-select.MuiInputBase-input {
      display: flex;
      align-items: center;
      min-height: 49px;
      padding: 0 50px 0 14px;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;

    .MuiSvgIcon-root {
      width: 100%;
      height: 100%;
      color: var(--theme-primary);
    }
  }

  &__placeholder-option.MuiMenuItem-root {
    display: none;
  }

  &__menu {
    margin-top: 5px;

    .MuiMenuItem-root {
      padding-top: 13px;
      padding-bottom: 13px;
    }
  }
}
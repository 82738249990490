:root {
  --theme-primary: #ff6500;
  --theme-lighter-alt: #fff9f5;
  --theme-lighter: #ffe7d6;
  --theme-light: #ffd1b3;
  --theme-tertiary: #ffa366;
  --theme-secondary: #ff781f;
  --theme-dark-alt: #e65c00;
  --theme-dark: #c24e00;
  --theme-darker: #8f3900;
  --neutral-lighter-alt: #faf9f8;
  --neutral-lighter: #f3f2f1;
  --neutral-light: #edebe9;
  --neutral-quaternary-alt: #e1dfdd;
  --neutral-quaternary: #d0d0d0;
  --neutral-tertiary-alt: #c8c6c4;
  --neutral-tertiary: #c4c7ca;
  --neutral-secondary: #8c9095;
  --neutral-secondary-alt: #8c9095;
  --neutral-primary-alt: #5a5f64;
  --neutral-primary: #464a4f;
  --neutral-dark: #35383c;
  --error-main: #e96767;
  --black: #272a2c;
  --white: #ffffff;
  --header-height: 52px;
}
